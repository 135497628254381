import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface Props {
    categories  : string[],
    target      : number[],
    realisation : number[]
}

const Chart : React.FC<Props> = ({ categories, target, realisation}) => {

    const options = {
        chart: {
            type: 'line'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories
        },
        yAxis: {
            title: {
                text: 'Quantity'
            }
        },
        plotOptions: {
            spline: {
                marker: {
                    radius: 4,
                    lineColor: '#666666',
                    lineWidth: 1
                }
            }
        },
        series: [{
            name  : 'Rencana',
            color : "#e83943",
            data  : target
        }, {
            name  : 'Realisasi',
            color : "#2525b4",
            data  : realisation
        }]
    }

    return (
        <>
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </>
    )
}

export default React.memo(Chart)