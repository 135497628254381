import React, {useCallback, useContext, useState} from "react";
import {Layout, Menu, Drawer, Button, Space, notification} from "antd";
import {Link} from "react-router-dom";
import { MenuOutlined} from "@ant-design/icons";
import {Auth} from "store";
import firebase from "firebase/app";
import "firebase/auth";
import {Store} from "rc-field-form/lib/interface";
import {useHistory} from "react-router-dom";
import SiginModal from "./modal";
import styles from "./styles.module.css";

const { Header} = Layout;

const FrontHeader : React.FC = () => {

    const [isVisible, setVisible]          = useState<boolean>(false);
    const [modalVisible, setModalVisible]  = useState<boolean>(false);
    const [loading, setLoading]            = useState<boolean>(false);
    const {user}                           = useContext(Auth);
    const history                          = useHistory();

    const onFinish = useCallback(async (values: Store): Promise<void> => {
        try  {

            setLoading(true);

            await firebase.auth()
                .signInWithEmailAndPassword(values.email, values.password);

            setTimeout(() => {

                history.push("/kelola/dashboard/");
            }, 1000)

        } catch (error) {

            let errorTitle: string;

            switch (error.code) {
                case 'auth/wrong-password' :
                    errorTitle = 'Wrong Password';
                    break;
                case 'auth/user-not-found' :
                    errorTitle = 'User Not Found';
                    break;
                default :
                    errorTitle = 'Sign in failed'
            }

            notification['error']({
                message: errorTitle,
                description: error.message
            });

            setLoading(false)

        }
    }, [history]);

    const menuItem = (mode : "horizontal" | "vertical" | "inline") => (
        <Menu className={mode === "horizontal" ? styles.menu : ""} style={{background : "transparent"}} theme="light" mode={mode}>
            <Menu.Item key="0">
                <Link className={"front-header"} to={"/"}>
                    <strong style={{color : mode === "horizontal" ? "#777777" : "#000", fontSize : 15}}>Home</strong>
                </Link>
            </Menu.Item>
            <Menu.Item key="1">
                <Link className={"front-header"} to={"/berita"}>
                    <strong style={{color : mode === "horizontal" ? "#777777" : "#000", fontSize : 15}}>Berita</strong>
                </Link>
            </Menu.Item>
            {/*<Menu.Item key="2">*/}
            {/*    <Link className={"front-header"} to={"/paket"}>*/}
            {/*        <strong style={{color : mode === "horizontal" ? "#777777" : "#000", fontSize : 15}}>Paket</strong>*/}
            {/*    </Link>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key="3" style={{background : "#fff"}}>
                {
                    user.uid ? (
                        <Link to={"/kelola/dashboard/"}>
                            <Button
                                type={"primary"}
                                ghost={false}
                                size={"large"}
                            >
                                <strong>Dashboard</strong>
                            </Button>
                        </Link>
                    ) : (
                        <Space>
                            <Button
                                type={"primary"}
                                ghost={true}
                                size={"large"}
                            >
                                <strong>Daftar</strong>
                            </Button>
                            <Button
                                type={"primary"}
                                ghost={false}
                                size={"large"}
                                onClick={() => setModalVisible(true)}
                            >
                                <strong>Masuk</strong>
                            </Button>
                        </Space>
                    )
                }
            </Menu.Item>
        </Menu>
    )

    return (
        <>

            <SiginModal
                onFinish={onFinish}
                loading={loading}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            />

            <Drawer
                title={null}
                placement={"right"}
                closable={false}
                visible={isVisible}
                onClose={() => setVisible(false)}
            >
                { menuItem("inline") }
            </Drawer>

            <Header className={styles.header}>
                <Space>
                    <img className={styles.logo} src={"/logo2.png"} alt={"Logo kemenhub"} />
                    <img className={styles.logo} src={"/logo.jpeg"} alt={"Logo dardela"} />
                    {/*<span  className={styles.logoText} >*/}
                    {/*    <strong>APLIKASI <br/> MONITORING <br/> SYSTEM</strong>*/}
                    {/*</span>*/}
                </Space>
                { menuItem("horizontal") }
                <Button
                    className={styles.buttonMenu}
                    shape={"circle"}
                    ghost={true}
                    type={"primary"}
                    size={"large"}
                    onClick={() => setVisible(true)}
                >
                    <MenuOutlined />
                </Button>
            </Header>
        </>
    )
}

export default FrontHeader;