import React from "react";
import { Card, Skeleton} from "antd";
import styles from "./styles.module.css";

const NewsSkeleton : React.FC = () => {

    return (
        <Card
            className={styles.newsCard}
            cover={<Skeleton.Avatar style={{width : "100%", height: 200}} shape={"square"} active={true} />}
        >
            <Card.Meta
                title={<Skeleton active={true} paragraph={{rows : 0}} />}
                description={(
                    <section>
                        <div>
                            <strong>
                                <Skeleton title={false} paragraph={{rows : 1}} />
                            </strong>
                        </div>
                        <div style={{textAlign: "right", paddingTop: 50}}>
                            <Skeleton.Button active={true} />
                        </div>
                    </section>
                )}
            />
        </Card>
    )
}

export default React.memo(NewsSkeleton);