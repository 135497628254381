import React  from "react";
import {Button, Input, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";

const Search : any = () => {
    return (dataIndex: string): any => ({

        filterDropdown: (actions: any) => (

            <div style={{padding: 8}}>
                <Input
                    value={actions.selectedKeys[0]}
                    onChange={e => actions.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                        onClick={actions.confirm}
                    >
                        Cari
                    </Button>
                    <Button
                        size="small"
                        style={{width: 90}}
                        onClick={actions.clearFilters}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),

        filterIcon: (filtered: boolean) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value : string, record : any) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    });
};

export default Search;
