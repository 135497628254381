import firebase from "firebase";

// convert s napshot to array
export const parseSnapshot = (snapshot : firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) : any[] => {

    let arr : any[] = [];

    snapshot.forEach((doc : any) => {

        let data = {...doc.data(), key : doc.id}

        if (doc.data().timestamp) {

            data = {...data, key : doc.id, timestamp : doc.data().timestamp?.toDate()}
        }

        if (doc.data().datetime) {

            data = {...data, key : doc.id, datetime : doc.data().datetime?.toDate()}
        }

        if (doc.data().deadline) {

            data = {...data, key : doc.id, deadline : doc.data().deadline?.toDate()}
        }

        if (doc.data().uploadDate) {

            data = {...data, key : doc.id, uploadDate : doc.data().uploadDate?.toDate()}
        }

        if (doc.data().documentDate) {

            data = {...data, key : doc.id, documentDate : doc.data().documentDate?.toDate()}
        }

        if (doc.data().in) {

            data = {...data, key : doc.id, in : doc.data().in?.toDate()}
        }

        arr.push(data)
    });

    return arr;
};

// delay
export const sleep = (time : number) : Promise<string> => {
    return new Promise(resolve => {
        setTimeout(() => {
            return resolve();
        }, time)
    })
};

// file to base65
export const toBase64 = (file : any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

// paginate array
export const paginate = (array : any[], page_size : number, page_number : number) : any[] => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
};
