import React, {ReactInstance, ReactText, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Layout, Menu, Button, Space} from 'antd';
import {
    PicLeftOutlined,
    PicRightOutlined,
    InboxOutlined,
    DatabaseOutlined,
    ContainerOutlined,
    FundProjectionScreenOutlined,
    TeamOutlined,
    FolderOutlined,
} from '@ant-design/icons';
import styles from './sider.module.css'
import {Auth} from "store";

const Sider: React.FC = () => {

    const [rootSubMenuKeys, ]               = useState<string[]>(['pengaturan', 'dokumen']);
    const [openKeys, setOpenKeys]           = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys]   = useState<string>('');
    const [collapse, setCollapse]           = useState<boolean>(false);
    const {user}                            = useContext(Auth);

    const onBreakpoint = (broken: boolean): void => {

        setCollapse(broken)
    };

    const onTrigger = (): void => {

        setCollapse(prevState => !prevState)
    };

    const onOpenChange = (keys : ReactText[] | { key: ReactText; item: ReactInstance; trigger: string; open: boolean; }) : void => {

        const latestOpenKey : string | number | undefined = (keys as string[]).find(key => openKeys.indexOf(key as string) === -1);

        if (latestOpenKey){

            if (rootSubMenuKeys.indexOf(latestOpenKey as string) === -1) {

                setOpenKeys(openKeys)
            }else{

                setOpenKeys( [latestOpenKey as string])
            }
        }else{

            setOpenKeys( [])
        }
    };

    useEffect(() => {

        let pathname : string = window.location.pathname;

        setSelectedKeys(pathname);

        setOpenKeys([pathname.split("/")[2]]);

    }, []);

    useEffect(() => {

        let body = document.querySelector(".ant-layout-content") as HTMLBodyElement;

        body.addEventListener("touchstart", () => {

           setCollapse(true);
        });

    }, []);

    return (
        <div>

            <div className={styles.buttonCollapse}>
                <Button shape={'circle'} size={'large'} onClick={onTrigger}>
                    {!collapse ? <PicLeftOutlined/> : <PicRightOutlined/>}
                </Button>
            </div>

            <Layout.Sider
                collapsed={collapse}
                breakpoint={"lg"}
                collapsedWidth={0}
                className={styles.sider}
                width={'250'}
                trigger={null}
                onBreakpoint={onBreakpoint}
                collapsible={true}
            >

                <div className={styles.logo} >
                   <Link to={"/"}>
                       <Space>
                           <img
                               style={{borderRadius : 10}}
                               width={80} src={"/logo2.png"}
                               alt={"logo"}
                           />
                           <img
                               style={{borderRadius : 10}}
                               width={80} src={"/logo.jpeg"}
                               alt={"logo"}
                           />
                       </Space>
                   </Link>
                </div>
                    <Menu
                        theme="light"
                        mode="inline"
                        selectedKeys={[selectedKeys]}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        style={{background: "transparent", marginTop : 30}}
                    >

                        <Menu.Item key={"/kelola/dashboard/"}>
                            <Link to={'/kelola/dashboard/'}>
                                <FundProjectionScreenOutlined style={{ fontSize: '16px', marginRight : 40 }} />
                                <span className="nav-text">Dashboard</span>
                            </Link>
                        </Menu.Item>

                        {
                            user.claims.role === "Satker" && (
                                <Menu.Item key={"/kelola/pengguna/"}>
                                    <Link to={'/kelola/pengguna/'}>
                                        <TeamOutlined style={{ fontSize: '16px', marginRight : 40  }} />
                                        <span className="nav-text">Pengguna</span>
                                    </Link>
                                </Menu.Item>
                            )
                        }

                        {
                            user.claims.role !== "guest" && <Menu.Item key={"/kelola/paket/"}>
                                <Link to={'/kelola/paket/'}>
                                    <InboxOutlined style={{ fontSize: '16px', marginRight : 40  }} />
                                    <span className="nav-text">Paket</span>
                                </Link>
                            </Menu.Item>

                        }

                        {
                            user.claims.role !== "guest" &&  <Menu.Item key={"/kelola/berita/"}>
                                <Link to={'/kelola/berita/'}>
                                    <ContainerOutlined style={{ fontSize: '16px', marginRight : 40  }}  />
                                    <span className="nav-text">Berita</span>
                                </Link>
                            </Menu.Item>
                        }


                        <Menu.Item key={"/kelola/arsip/"}>
                            <Link to={'/kelola/arsip/'}>
                                <DatabaseOutlined  style={{ fontSize: '16px', marginRight : 40  }}  />
                                <span className="nav-text">Arsip</span>
                            </Link>
                        </Menu.Item>

                        {
                            user.claims.role !== "guest" &&  <Menu.SubMenu
                                key={'dokumen'}
                                title={
                                    <span>
                                   <FolderOutlined style={{ fontSize: '16px', marginRight : 40  }} />
                                  <span>Dokumen</span>
                                </span>
                                }
                            >
                                <Menu.Item key={"/kelola/dokumen/shop-drawing/"}>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/shop-drawing/'}>
                                        <span className="nav-text">Shop Drawing</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/sas/"}>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/sas/'}>
                                        <span className="nav-text">SAS</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/mss/"}>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/mss/'}>
                                        <span className="nav-text">MSS</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/wps/"}>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/wps/'}>
                                        <span className="nav-text">WPS</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/rfi/"}>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/rfi/' }>
                                        <span className="nav-text">RFI</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/rfm/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/rfm/'}>
                                        <span className="nav-text">RFM</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/laporan-mingguan/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/laporan-mingguan/'}>
                                        <span className="nav-text">Laporan Mingguan</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/laporan-bulanan/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/laporan-bulanan/'}>
                                        <span className="nav-text">Laporan Bulanan</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/asbuilt-drawing/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/asbuilt-drawing/'}>
                                        <span className="nav-text">Asbuilt Drawing</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/data-ukur/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/data-ukur/'}>
                                        <span className="nav-text">Data Ukur</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/data-pengujian/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/data-pengujian/'}>
                                        <span className="nav-text">Data Pengujian</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/addendum/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/addendum/'}>
                                        <span className="nav-text">Addendum</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"/kelola/dokumen/kontrak/" }>
                                    <Link style={{paddingLeft : 31}} to={'/kelola/dokumen/kontrak/'}>
                                        <span className="nav-text">Kontrak</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={"" }>

                                </Menu.Item>
                            </Menu.SubMenu>
                        }

                    </Menu>
            </Layout.Sider>
        </div>
    )
};

export default Sider;
