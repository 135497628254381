import React, {useContext} from 'react';
import {Layout, Avatar, Popover, Tag, Button} from 'antd';
import { LogoutOutlined} from "@ant-design/icons";
import {Auth} from "store";
import firebase from "firebase/app";
import "firebase/auth";

import styles from './header.module.css'

import PooperContent from "./pooper.header";

const Header: React.FC = () => {

    const {user} = useContext(Auth);

    const signOut = async (): Promise<void> => {

        await firebase.auth().signOut();
    };

    return (
        <Layout.Header className={styles.header}>
            <div className={styles.layoutHeader}>
                <ul>
                    <li className={styles.inline}>
                        <Popover
                            placement="bottomRight"
                            trigger="click"
                            title={null}
                            content={
                                <PooperContent
                                    displayName={user?.claims?.role}
                                    email={user?.email}
                                    photoURL={JSON.parse(user?.photoURL || "[{}]")[0]?.response?.url || 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'}
                                    signOut={signOut}
                                />
                            }
                        >
                            <Tag
                                color="purple"
                                style={{
                                    paddingRight: 20,
                                    borderRadius: 20,
                                    paddingLeft : 1,
                                }}
                            >
                                <Avatar
                                    style={{cursor: 'pointer'}}
                                    src={JSON.parse(user?.photoURL || "[{}]")[0]?.response?.url || 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'}
                                    size={'large'}
                                />
                                <strong style={{marginLeft: 10}}>{user.displayName?.slice(0, 20)}  ({user?.claims?.role})</strong>
                            </Tag>
                        </Popover>
                    </li>
                    <li className={styles.inline}>
                        <Button onClick={signOut} style={{marginLeft: 6}} size={"large"} shape={"circle"} danger={true} ghost={true}>
                            <LogoutOutlined />
                        </Button>
                    </li>

                </ul>
            </div>
        </Layout.Header>
    )
};

export default React.memo(Header);
