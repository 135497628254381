import React, {Dispatch} from "react";
import {Button, Table} from "antd";
import {search} from "modules";
import dateformat from "dateformat";
import {ArrowLeftOutlined} from "@ant-design/icons";

interface Props {
    dataSource : any[],
    isLoading  : boolean,
    setDetail  : Dispatch<{active : boolean, record : any}> | null
}

const FileLIst : React.FC<Props> = ({dataSource,  isLoading, setDetail}) => {

    const columns = [
        {
            title: 'Nama File',
            dataIndex: 'name',
            key: 'name',
            ...search()("name")
        },
        {
            title: 'Tanggal Upload File',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render : (value : any) => {

                return value ? dateformat(value.toDate(), "dd mmmm yyyy") : "-"
            }
        },
        {
            title: '',
            dataIndex: 'response',
            key: 'response',
            align : "right",
            render : (value : {url : string, name : string}) => {

                return <div style={{width: 150}}>
                    <Button
                        type={"primary"}
                        download={value.name}
                        href={value.url}
                        target={"_blank"}
                    >
                        Download
                    </Button>
                </div>

            }
        }
    ]

    return (
        <section>
            {
                setDetail && (
                    <div>
                        <Button
                            shape={"circle"}
                            type={"primary"}
                            ghost={true}
                            onClick={() => setDetail({active : false, record : {}})}
                        >
                            <ArrowLeftOutlined />
                        </Button>
                    </div>
                )
            }

            <div
                style={{
                    marginTop    : 10,
                    background   : "#fff",
                    borderRadius : "10px",
                    boxShadow    : "0 0 8px 0 rgba(0, 0, 0, 0.12)"
                }}
            >
                <div style={{overflowY   : "auto",}}>
                    <Table
                        loading={isLoading}
                        dataSource={dataSource}
                        columns={columns}
                    />
                </div>
            </div>
        </section>
    )
}

export default React.memo(FileLIst)