import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {Spinner} from "components";
import {AuthProvider} from "store";
import AuthRoute from "./auth";

const Landing        : React.FC = React.lazy(() => import('../pages/front/landing'));
const News           : React.FC = React.lazy(() => import('../pages/front/news'));
const WebView        : React.FC = React.lazy(() => import('../pages/front/webview'));
const WebView2       : React.FC = React.lazy(() => import('../pages/front/webview2'));

const Login          : React.FC = React.lazy(() => import('../pages/back/login'));
const Dashboard      : React.FC = React.lazy(() => import('../pages/back/dashboard'));
const User           : React.FC = React.lazy(() => import('../pages/back/user'));
const Package        : React.FC = React.lazy(() => import('../pages/back/package'));
const _News          : React.FC = React.lazy(() => import('../pages/back/news'));
const Archive        : React.FC = React.lazy(() => import('../pages/back/archives'));
const ShopDrawing    : React.FC = React.lazy(() => import('../pages/back/shopDrawing'));
const SAS            : React.FC = React.lazy(() => import('../pages/back/sas'));
const MSS            : React.FC = React.lazy(() => import('../pages/back/mss'));
const WPS            : React.FC = React.lazy(() => import('../pages/back/wps'));
const RFM            : React.FC = React.lazy(() => import('../pages/back/rfm'));
const RFI            : React.FC = React.lazy(() => import('../pages/back/rfi'));
const WeeklyReport   : React.FC = React.lazy(() => import('../pages/back/weeklyReport'));
const MonthlyReport  : React.FC = React.lazy(() => import('../pages/back/monthlyReport'));
const AsbuiltDrawing : React.FC = React.lazy(() => import('../pages/back/asbuiltDrawing'));
const MeasuringData  : React.FC = React.lazy(() => import('../pages/back/measuringData'));
const TestingData    : React.FC = React.lazy(() => import('../pages/back/testingData'));
const Addendum       : React.FC = React.lazy(() => import('../pages/back/addendum'));
const Contract       : React.FC = React.lazy(() => import('../pages/back/contract'));

const Router : React.FC = () => {
    return (
        <AuthProvider>
            <React.Suspense fallback={<Spinner tip={'Mohon tunggu...'} spinning={true} />}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={'/'} component={Landing} />
                        <Route     path={'/berita/:id?/:title?'} component={News} />
                        <Route     path={'/berita/:id?/:title?'} component={News} />
                        <Route     path={'/curva-s/:packageId'} component={WebView} />
                        <Route     path={'/document-file/:documentName/:documentId'} component={WebView2} />

                        <Route     path={'/masuk'} component={Login} />
                        <AuthRoute path={'/kelola/dashboard'} component={Dashboard} />
                        <AuthRoute path={'/kelola/pengguna'} component={User} />
                        <AuthRoute path={'/kelola/berita'} component={_News} />
                        <AuthRoute path={'/kelola/arsip'} component={Archive} />
                        <AuthRoute path={'/kelola/paket'} component={Package} />
                        <AuthRoute path={'/kelola/dokumen/shop-drawing'} component={ShopDrawing} />
                        <AuthRoute path={'/kelola/dokumen/sas'} component={SAS} />
                        <AuthRoute path={'/kelola/dokumen/mss'} component={MSS} />
                        <AuthRoute path={'/kelola/dokumen/wps'} component={WPS} />
                        <AuthRoute path={'/kelola/dokumen/rfm'} component={RFM} />
                        <AuthRoute path={'/kelola/dokumen/rfi'} component={RFI} />
                        <AuthRoute path={'/kelola/dokumen/laporan-mingguan'} component={WeeklyReport} />
                        <AuthRoute path={'/kelola/dokumen/laporan-bulanan'} component={MonthlyReport} />
                        <AuthRoute path={'/kelola/dokumen/asbuilt-drawing'} component={AsbuiltDrawing} />
                        <AuthRoute path={'/kelola/dokumen/data-ukur'} component={MeasuringData} />
                        <AuthRoute path={'/kelola/dokumen/data-pengujian'} component={TestingData} />
                        <AuthRoute path={'/kelola/dokumen/addendum'} component={Addendum} />
                        <AuthRoute path={'/kelola/dokumen/kontrak'} component={Contract} />

                    </Switch>
                </BrowserRouter>
            </React.Suspense>
        </AuthProvider>
    )
};

export default Router;
