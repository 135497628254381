import React from "react";
import styles from "./styles.module.css";
import {Space} from "antd";

const Footer : React.FC = () => {

    return (
        <>
            <div className={styles.footerWrapper}>
                <section className={styles.footer}>
                    <div style={{textAlign : "center"}}>
                        <Space>
                            <img alt={"unismuh"} style={{width : 100, borderRadius: 10}} src={"/logo2.png"} />
                            <img alt={"unismuh"} style={{width : 100, borderRadius: 10}} src={"/logo.jpeg"} />
                        </Space>
                        <p style={{color: "#888", marginTop : 20}}>
                            Pembangunan Perkeretaapian <br/> Makassar - Pare-pare
                        </p>
                    </div>
                </section>
            </div>
            <div style={{
                color     : "#888",
                textAlign : "center",
                padding   : "20px 0",
                border    : "1px solid #dedede"
            }}>
                Pengawasan/Supervisi Pembangunan Jalur KA dan Jembatan KA di KM 44+100 sd KM 73+600 (MYC 2018-2020).
            </div>
        </>
    )
}

export default Footer;