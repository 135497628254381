import React from "react";
import {Descriptions} from "antd";
import dateformat from "dateformat";

interface Props {
    revision : any[],
    status   : string
}

const Revision : React.FC<Props> = ({revision, status}) => {

    return (
        <>
            {
                (revision || []).map((item : any, x : number) => (
                    <Descriptions
                        key={x}
                        title={null}
                        layout={"vertical"}
                        bordered={false}
                    >
                        <Descriptions.Item
                            label={
                                <strong style={{color : "#7165e3"}}>
                                    Masuk {x === 0 ? "" : "R.0" + x }
                                </strong>
                            }
                            style={{paddingBottom: 0, verticalAlign: "bottom"}}
                        >
                            <p style={{fontSize: 20, marginTop: 5}}>
                                {item.in?.toDate() ? dateformat(item.in.toDate(), "dd mmmm yyyy") : "-"}
                            </p>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <strong style={{color : "#7165e3"}}>
                                    Keluar { (revision.length - 1) === x && status === "disetujui" ? "" : "R.0" + (x + 1)}
                                </strong>
                            }
                            style={{paddingBottom: 0, verticalAlign: "bottom"}}
                        >
                            <p style={{fontSize: 20, marginTop: 5}}>
                                {item.out?.toDate() ? dateformat(item.out.toDate(), "dd mmmm yyyy") : "-"}
                            </p>
                        </Descriptions.Item>

                        <Descriptions.Item
                            label={
                                <strong style={{color : "#7165e3"}}>
                                    Deskripsi
                                </strong>
                            }
                            style={{paddingBottom: 0, verticalAlign: "bottom"}}
                        >
                            <p style={{fontSize: 20, marginTop: 5}}>
                                {item.commentary}
                            </p>
                        </Descriptions.Item>

                    </Descriptions>
                ))
            }
        </>
    )
}

export default React.memo(Revision);