import React, {ReactNode, useState} from "react";
import {Modal, Form, Input, Button, Upload, message, Tabs} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import { toBase64} from "utils";
import {Store} from "antd/es/form/interface"
import {UploadFile} from "antd/es/upload/interface";
import axios from "type-axios";

interface Props {
    isVisible     : boolean,
    handleVisible : (value : boolean) => void,
    document      : string,
    uploadFile    : (file : {uid : string, name : string, response : { url : string}, timestamp : Date}, document : string) => Promise<void>,
}

const Index : React.FC<Props> = ({isVisible,  handleVisible, uploadFile, document}) => {

    const [file, setFile]            = useState<UploadFile[]>([]);
    const [link, setLink]           = useState<string>("");
    const [isLoading, setLoading]   = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<string>("1");
    const [form]                    = Form.useForm()

    const reset = () : void => {
        handleVisible(false);
        setLink("");
        setFile([]);
    }

    const onFinish = async (values : Store) : Promise<void> => {

        setLoading(true);

        const result = file.length > 0 ? {
            uid       : file[0].uid,
            name      : file[0].name,
            response  : file[0].response,
            timestamp : new Date()
        } : {
            uid       : "input-url",
            name      : values.nameFile,
            response  : {url : values.URLFile},
            timestamp : new Date()
        }

        await uploadFile(result, document as string);

        setLoading(false);

        reset();
    }

    return (
        <Modal
            visible={isVisible}
            footer={null}
            closable={false}
            centered={true}
        >
            <Form
                form={form}
                layout={"vertical"}
                onFinish={onFinish}
            >

                <Tabs defaultActiveKey={"1"} onChange={activeKey => setActiveKey(activeKey)} >
                    <Tabs.TabPane key={"1"} tab={"Upload FIle"}>
                        <Form.Item
                            name={"uploadFile"}
                            rules={[() => ({
                                validator() {
                                    if (file.length > 0 || activeKey === "2") return Promise.resolve();
                                    return Promise.reject("FIle wajib diisi.")
                                }
                            })]}
                        >
                            <Upload.Dragger
                                name={"files"}
                                multiple={false}
                                fileList={file}
                                customRequest={async ({file, onSuccess, onProgress, onError}) => {
                                    try {

                                        let response = await axios("https://us-central1-com-wasdlabs-pms-de76b.cloudfunctions.net/upload", {
                                            method : "POST",
                                            data   : {
                                                file   : await toBase64(file),
                                                title : `${file.uid}-${file.name}`
                                            },
                                            onUploadProgress : e => {

                                                let percent = (e.loaded / e.total) * 100;

                                                onProgress({percent}, file);
                                            }
                                        });

                                        onSuccess(response.data, file);

                                    } catch (error) {

                                        onError(error)
                                    }
                                }}
                                progress={{
                                    strokeColor: {
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                    },
                                    strokeWidth: 3,
                                    format : (percent) => (<b>{parseFloat((percent as number).toFixed(2))}%</b>) as ReactNode
                                }}
                                onChange={info => {

                                    const { status } = info.file

                                    setLoading(true)

                                    if (status === undefined) {

                                        setFile([]);

                                        setLoading(false)

                                        message.error("File yang anda unggah bukan gambar.")
                                    }

                                    if (status === 'uploading') {

                                        setFile([info.file]);
                                    }

                                    if (status === 'done') {

                                        setFile([info.file]);

                                        setLoading(false);

                                        message.success(`${info.file.name} file uploaded successfully.`);

                                    } else if (status === 'error') {

                                        setFile([]);

                                        setLoading(false);

                                        message.error(`${info.file.name} file upload failed.`);
                                    }

                                    if (status === "removed") {

                                        setFile([]);

                                        setLoading(false);
                                    }
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-hint">Klik atau drag file rar untuk mengupload.</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key={"2"} tab={"Link File"}>
                        <Form.Item label={"Nama file"} name={"nameFile"} rules={[{ required : true, message: "Nama file wajib diisi."}]}>
                            <Input size={"large"}  />
                        </Form.Item>
                        <Form.Item label={"URL File"} name={"URLFile"} rules={[{ required : true, message: "URL file wajib diisi." }]}>
                            <Input value={link} onChange={e => setLink(e.target.value)} size={"large"} />
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>

                <Form.Item>
                    <div style={{textAlign: "right", marginTop : 20}}>
                        <Button
                            disabled={isLoading}
                            htmlType="button"
                            onClick={reset}
                            type={"primary"}
                            ghost={true}
                        >
                            Batal
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            loading={isLoading}
                            htmlType="submit"
                            type={"primary"}
                        >
                            Simpan
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default React.memo(Index)