import React from 'react';
import {Spin} from 'antd';
import styles from "./spin.module.css";

interface Props {
    tip: string,
    spinning: boolean
}

const Spinner: React.FC<Props> = ({spinning, tip}) => {

    return (
        <div className={styles.div}>
            <Spin
                className={styles.spin}
                size={"large"}
                spinning={spinning}
                // tip={tip}
                indicator={
                    <img
                        src={"/images/loader.gif"}
                        alt={""}
                        style={{
                            width : 300,
                            height : 300
                        }}
                    />
                }
            />
        </div>
    )
};

export default Spinner;
