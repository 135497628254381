import React, {useState, useEffect} from 'react';
import {Spinner} from "components";
import firebase from "firebase/app";
import "firebase/auth";

// We define our type for the context properties right here
type ContextProps = {
    authenticated: boolean,
    user : any,
};

export const Auth = React.createContext<ContextProps>({
    authenticated : false,
    user : {},
});

interface ProviderProps {

    children : React.ReactChild
}

export const AuthProvider : React.FC<ProviderProps> = ({children}) => {

    const [authenticated, setAuthenticated] = useState<boolean>(true);
    const [user, setUser]                   = useState<object>({});
    const [loading, setLoading]             = useState<boolean>(false);

    useEffect(() => {

        setLoading(true);

        firebase.auth().onAuthStateChanged( async user => {
            if (user) {

                let idTokenResult = await firebase.auth()?.currentUser?.getIdTokenResult(true);
                let claims        = idTokenResult?.claims

                setUser({...user, claims });
                setAuthenticated(true);
                setLoading(false);

            } else {

                setAuthenticated(false);
                setUser({});
                setLoading(false);
            }
        })
    }, []);

    return (
        <Auth.Provider
            value={{ authenticated, user}}
        >
            {
                loading
                    ? <Spinner spinning={true} tip={'Mencoba terhubung ke server...'} />
                    : children
            }
        </Auth.Provider>
    )
};
