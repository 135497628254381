import React, {Dispatch} from "react";
import { Button, Space, Modal,  Form, Input, Checkbox} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {Store} from "rc-field-form/lib/interface";

interface Props {
    onFinish: (values: Store) => void;
    loading : boolean;
    modalVisible : boolean,
    setModalVisible : Dispatch<boolean>
}

const SiginModal : React.FC<Props> = ({onFinish, loading, modalVisible, setModalVisible}) => {

    return (
        <Modal
            visible={modalVisible}
            footer={null}
            closable={false}
            width={350}
            centered={true}
        >
            <div style={{ textAlign: "center", marginBottom : 20}}>
                    <span>
                        <img width={"80%"} src={"/images/login.svg"} alt={""} />
                    </span>
            </div>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{remember: true}}
                layout={"vertical"}
                onFinish={onFinish}
            >
                <Form.Item
                    label={'Email'}
                    name={'email'}
                    rules={[{required: true, message: 'Email wajib diisi.'}]}
                >
                    <Input
                        prefix={<UserOutlined/>}
                        size={'large'}
                    />
                </Form.Item>
                <Form.Item
                    label={'Kata sandi'}
                    name={'password'}
                    rules={[{required: true, message: 'Kata sandi wajib diisi'}]}
                >
                    <Input.Password
                        prefix={<LockOutlined/>}
                        type="password"
                        size={'large'}
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Ingat saya</Checkbox>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <div style={{textAlign: "right"}}>
                        <Space>
                            <Button
                                htmlType={"button"}
                                onClick={() => setModalVisible(false)}
                                danger={true}
                                size={"large"}
                                disabled={loading}
                            >
                                Batal
                            </Button>
                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                                size={"large"}
                                loading={loading}
                            >
                                Masuk
                            </Button>
                        </Space>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default React.memo(SiginModal);